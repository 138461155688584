// Components
import RestaurantImprintModal from "components/modals/RestaurantImprintModal"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { useShop } from "hooks/firestore/simple/useShop"
import { useState } from "react"
import styled from "styled-components"
import Shop from "types/firestore/shop"

const StyledSpan = styled.a`
  color: var(--secondary);
  cursor: pointer;
  /* display: block; */
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    color: var(--primary);
    text-decoration: underline;
  }
`

const RestaurantImprintButton = ({ shop }: { shop: WithRef<Shop> }) => {
  const [infoModalOpen, setInfoModalOpen] = useState(false)

  const toggleInfoModal = () => {
    setInfoModalOpen(!infoModalOpen)
  }
  return (
    <>
      <StyledSpan onClick={toggleInfoModal} id="restaurant-imprint-button">
        Impressum
      </StyledSpan>
      <RestaurantImprintModal restaurant={shop} isOpen={infoModalOpen} onBackgroundClick={toggleInfoModal} />
    </>
  )
}

export default RestaurantImprintButton

export function ShopImprintButton() {
  const shop = useShop()

  return shop ? <RestaurantImprintButton shop={shop} /> : null
}
