import { Close } from "components/LEGACY/styled-components/Icons"
import GetpackedTitle from "components/reusable/admin/GetpackedTitle"
import { ReactNode } from "react"
import ReactDom from "react-dom"
import styles from "./Modal.module.scss"

interface ModalProps {
  title?: string
  subline?: string
  open: boolean
  onClose: () => void
  bottom?: boolean
  control?: boolean // Move the first child next to the title
  noCloseButton?: boolean
  children: ReactNode
  applyBodyPadding?: boolean
}

const Modal = ({
  children,
  title,
  subline,
  open,
  onClose,
  bottom = false,
  control,
  noCloseButton,
  applyBodyPadding,
}: ModalProps) => {
  return open
    ? ReactDom.createPortal(
        <div className={`${styles.wrapper} ${bottom ? styles.bottom : styles.center}`}>
          <div className={`${styles.overlay}`} onClick={onClose} />
          <section className={`${styles.inner} ${control ? styles.control : ""}`}>
            <section className={`${styles.meta}`}>
              {title && <GetpackedTitle title={title} />}
              {subline && <span>{subline}</span>}
            </section>
            {!noCloseButton && (
              <button className={`${styles.closeButton}`} onClick={onClose}>
                <Close color={"black"} />
              </button>
            )}
            {applyBodyPadding ? <div className="tw-p-2">{children}</div> : children}
          </section>
        </div>,
        document.body
      )
    : null
}

export default Modal
