import { PageTitle, Title as TitlePage } from "components/LEGACY/styled-components/Page"

interface TitleProps {
  title: string
}

function GetpackedTitle({ title }: TitleProps) {
  return (
    <PageTitle>
      {/* <Subtitle>getpacked</Subtitle> */}
      <TitlePage>{title}</TitlePage>
    </PageTitle>
  )
}

export default GetpackedTitle
