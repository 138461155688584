import Modal from "components/Modal"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import styled from "styled-components"
import Shop from "types/firestore/shop"

const BreakSpan = styled.span<{ bold?: boolean }>`
  display: block;
  font-weight: ${props => props.bold && "bold"};
`

const RestaurantImprintModal = ({
  restaurant,
  isOpen,
  onBackgroundClick,
}: {
  restaurant: WithRef<Shop>
  isOpen: boolean
  onBackgroundClick: () => void
}) => {
  return (
    <Modal open={isOpen} onClose={onBackgroundClick} title="Impressum">
      <div style={{ margin: "1rem" }}>
        <h4>{restaurant?.name ?? ""}</h4>
        <BreakSpan>{restaurant?.contact?.companyName}</BreakSpan>
        <BreakSpan>{restaurant?.contact?.address}</BreakSpan>
        <BreakSpan>{`${restaurant?.contact?.zipCode} ${restaurant?.contact?.city}`}</BreakSpan>
        <br />
        <BreakSpan>{`Tel.: ${restaurant?.contact?.phone}`}</BreakSpan>
        <BreakSpan>{`E-Mail.: ${restaurant?.contact?.email}`}</BreakSpan>
        <br />
        {restaurant?.contact?.registerTown && restaurant?.contact?.registerNum && (
          <>
            <BreakSpan bold>Handelsregister:</BreakSpan>
            <BreakSpan>{`Registergericht: ${restaurant?.contact?.registerTown}`}</BreakSpan>
            <BreakSpan>{`Registernummer: ${restaurant?.contact?.registerNum}`}</BreakSpan>
            <br />
          </>
        )}
        {restaurant?.contact?.representativeFirstName && (
          <>
            <BreakSpan bold>Vertretungsberechtigter:</BreakSpan>
            <BreakSpan>{`${restaurant?.contact?.representativeFirstName} ${restaurant?.contact?.representativeLastName}`}</BreakSpan>
            <br />
          </>
        )}
        {restaurant?.contact?.vatNum && (
          <>
            <BreakSpan bold>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:</BreakSpan>
            <BreakSpan>{restaurant?.contact?.vatNum}</BreakSpan>
            <br />
          </>
        )}

        <BreakSpan>
          Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter{" "}
          <a href="https://ec.europa.eu/consumers/odr." target="_blank" rel="noreferrer">
            https://ec.europa.eu/consumers/odr
          </a>{" "}
          finden. Unsere E-Mail-Adresse finden Sie im vorstehenden Impressum. <br />
          Wir sind weder bereit noch verpflichtet, an einem Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.
        </BreakSpan>
        <hr />
        <BreakSpan>
          Dieser Betrieb nutzt die Platform getpacked für die Annahme und Abwicklung von Bestellungen. Mehr dazu unter{" "}
          <a href="https://getpacked.de" target="_blank" rel="noreferrer">
            getpacked.de
          </a>
        </BreakSpan>
      </div>
    </Modal>
  )
}

export default RestaurantImprintModal
