import styled from "styled-components"
import { Flex } from "./Flex"

export const PageHeader = styled(Flex)`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding: 0.5rem 0 0.5rem 0;
  width: 50%;
  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    padding: 1.5rem 0 1rem 0;
    width: 100%;
  }
`

export const PageTitle = styled.div``

export const Subtitle = styled.span`
  display: block;
  color: ${props => props.theme.lightgrey};
  letter-spacing: 0.125rem;
  font-size: 0.625rem;
  text-transform: uppercase;
`

export const Title = styled.span`
  display: block;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
  color: var(--primary);
`
