import { createContext, ReactNode } from "react"

interface ShopIdContextType {
  shopId?: string
}

export const ShopIdContext = createContext<ShopIdContextType>({})

interface ShopIdContextProviderProps {
  shopId?: string
  children: ReactNode
}

const ShopIdContextProvider = ({ children, shopId }: ShopIdContextProviderProps) => {
  return <ShopIdContext.Provider value={{ shopId }}>{children}</ShopIdContext.Provider>
}

export default ShopIdContextProvider
