import styled from "styled-components"

interface FlexProps {
  readonly wrapReverse?: boolean
  readonly noWrap?: boolean
  readonly justifyContent?: string
  readonly justifyCenter?: boolean
  readonly justifyAround?: boolean
  readonly justifyBetween?: boolean
  readonly justifyEnd?: boolean
  readonly alignItems?: string
  readonly alignStretch?: boolean
  readonly alignBaseline?: boolean
  readonly alignEnd?: boolean
  readonly alignCenter?: boolean
  readonly alignContent?: boolean
  readonly content?: string
  readonly contentStart?: boolean
  readonly contentEnd?: boolean
  readonly contentCenter?: boolean
  readonly contentBetween?: boolean
  readonly contentAround?: boolean
  readonly column?: boolean
  readonly height?: string
  readonly margin?: string
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  flex-wrap: ${props => {
    if (props.wrapReverse) return "wrap-reverse"
    if (props.noWrap) return "nowrap"
    return "wrap"
  }};
  justify-content: ${props => {
    return (
      (props.justifyContent && props.justifyContent) ||
      (props.justifyCenter && "center") ||
      (props.justifyAround && "space-around") ||
      (props.justifyBetween && "space-between") ||
      (props.justifyEnd && "flex-end") ||
      "flex-start"
    )
  }};
  align-items: ${props => {
    return (
      (props.alignItems && props.alignItems) ||
      (props.alignStretch && "stretch") ||
      (props.alignEnd && "flex-end") ||
      (props.alignCenter && "center") ||
      (props.alignBaseline && "baseline") ||
      "flex-start"
    )
  }};
  align-content: ${props => {
    return (
      (props.alignContent && props.content) ||
      (props.contentStart && "flex-start") ||
      (props.contentEnd && "flex-end") ||
      (props.contentCenter && "center") ||
      (props.contentBetween && "space-between") ||
      (props.contentAround && "space-around") ||
      "stretch"
    )
  }};
  flex-direction: ${props => (props.column ? "column" : "row")};
  height: ${props => props.height || "auto"};
  margin: ${props => props.margin};
`

interface ColumnProps {
  readonly center?: boolean
  readonly left?: boolean
  readonly right?: boolean
  readonly justify?: boolean
  readonly width?: string
  readonly three?: boolean
  readonly four?: boolean
  readonly five?: boolean
  readonly alignStart?: boolean
  readonly alignEnd?: boolean
  readonly alignCenter?: boolean
  readonly alignBaseline?: boolean
  readonly alignStretch?: boolean
  readonly noPadding?: boolean
  readonly padding?: string
  readonly margin?: string
  readonly order?: number
}

export const Column = styled.div<ColumnProps>`
  text-align: ${props => {
    return (
      (props.center && "center") || (props.left && "left") || (props.right && "right") || (props.justify && "justify")
    )
  }};
  width: ${props => {
    return (
      (props.width && props.width) ||
      (props.three && "33.33%") ||
      (props.four && "25%") ||
      (props.five && "20%") ||
      "50%"
    )
  }};
  align-self: ${props => {
    return (
      (props.alignStart && "flex-start") ||
      (props.alignEnd && "flex-end") ||
      (props.alignCenter && "center") ||
      (props.alignBaseline && "baseline") ||
      (props.alignStretch && "stretch") ||
      "auto"
    )
  }};
  padding: ${props => (props.noPadding && "0") || props.padding || "1rem"};
  margin: ${props => props.margin || "0"};
  order: ${props => props.order};
`

interface ResponsiveCenterColumnProps {
  readonly width?: number
}

// ts-prune-ignore-next
export const ResponsiveCenterColumn = styled(Column)<ResponsiveCenterColumnProps>`
  width: ${props => (props.width && props.width) || "90%"};
  order: ${props => props.order};
  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    width: ${props => (props.width && (props.width as unknown as number) / 2) || "50%"};
  }
`

interface ResponsiveHalfColumnProps {
  readonly orderSmall?: number
  readonly orderMedium?: number
}

export const ResponsiveHalfColumn = styled(Column)<ResponsiveHalfColumnProps>`
  width: 100%;
  order: ${props => props.orderSmall};
  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    width: 49%;
    order: ${props => props.orderMedium};
  }
`

interface ResponsiveThirdColumnProps {
  readonly orderSmall?: number
  readonly orderMedium?: number
}

export const ResponsiveThirdColumn = styled(Column)<ResponsiveThirdColumnProps>`
  width: 100%;
  order: ${props => props.orderSmall};
  @media (min-width: ${props => props.theme.breakpoints.large}) {
    width: 32%;
    order: ${props => props.orderMedium};
  }
`

interface ResponsiveTwoThirdsColumnProps {
  readonly orderSmall?: number
  readonly orderMedium?: number
}

export const ResponsiveTwoThirdsColumn = styled(Column)<ResponsiveTwoThirdsColumnProps>`
  width: 100%;
  order: ${props => props.orderSmall};
  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    width: 65%;
    order: ${props => props.orderMedium};
  }
`
